<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Empresas</h3>
        </div>
        <div class="card-body">
              <b-table
              :fields="['nome', 'acoes']"
              :items="lista_empresas"
              :per-page="perPage"
              :current-page="currentPage"
              id="empresa-table"
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              show-empty
              empty-text="Nenhum registro encontrado!"
          >

                <template #head(acoes)><span></span></template>
           <template #cell(acoes)="{item}">
              <div class="text-right w-100">
                <button
                    v-show="lista_permissoes_filial.u_Empresa"

                    @click="atualizar(item)"
                     class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Editar registro"
                >
                  <i class="far fa-edit text-primary"></i>
                </button>

                <button
                    v-show="lista_permissoes_filial.lock_Empresa"
                    @click="confirm(item)"
                           class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-unlock-alt text-danger"></i>
                         </button>
              </div>
            </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="lista_empresas.length"
              :per-page="perPage"
              aria-controls="empresa-table"
          >
          </b-pagination>
      </div>
    </div>
  </div>
  </div>
</template>

<script>

import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";

export default {
  data() {
    return {
            currentPage: 1,
      perPage: 10
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Empresa"}]);
  },
  // created() {
  //   this.lista_matriculas();
  // },
  computed: {
    lista_empresas() {
      return this.$store.state.configEmpresa.lista_empresas;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    }
  },

  created() {
    this.listar_empresas()
  },
  methods: {
    async listar_empresas() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("configEmpresa/listar_empresas").finally(() => {
        this.$store.dispatch("configEmpresa/MudarPreloader", false);
        this.$store.dispatch('configEmpresa/atualizar', '')
      });
    },
    atualizar(value) {
      this.$router.push({name: "createEmpresa"})
      this.$store.dispatch('configEmpresa/atualizar', value)
    }


  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>